//=================================================================================
var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 1024;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  $(".widelink").on('click',function(){
    window.location=$(this).find("a").attr("href");
    return false;
	});
	barFix();//追従処理

  //スムーススクロール（ページ内リンク）
  $("a[href^='#'], .js-scroll").on("click", function () {
    var f = 600;
    var e = $(this).attr("href");
    var g = $(e == "#" || e == "" ? "html" : e);
    var d = g.offset().top;
    var h = g.offset().top - 80;
    if (default_width <= break_point) {
      $("html, body").animate({
        scrollTop: h
      }, f, "swing");
      return false
    } else {
      $("html, body").animate({
        scrollTop: d
      }, f, "swing");
      return false
    }
  });

	var snsBtn = $('.snsList');
  if (default_width >= break_point) {
    snsBtn.hide();
  }
	// スクロールが画面の半分に達したらボタン表示
	$(window).scroll(function () {
		if (default_width >= break_point && $(this).scrollTop() > window.innerHeight / 2) {
				snsBtn.fadeIn();
		} else if (default_width >= break_point && $(this).scrollTop() < window.innerHeight / 2) {
				snsBtn.fadeOut();
		} else {
      snsBtn.fadeIn();
    }
	});

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  var getDevice = (function() {
  var ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-----------------------------------*/
      /* PCの時
      /*-----------------------------------*/
      $(".widelink").hover(
        function(e) {
            $(this).addClass("hover");
        },
        function(e) {
            $(this).removeClass("hover");
        }
      );
      if ($(".worksList__item").length) {
        $('.worksList__item .botItem').tile();
      }
    } else {
      $(document).ready(function() {
        if ($(".worksList__item").length) {
          $(".works__cont .slick-slide .botItem").tile();
        }
      });
    }
  })();

	/*------------------------*/
	//バーガーメニュー表示・非表示
	/*------------------------*/
	//バーガーボタンクリック
	$(".burger").on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= break_point) {
			$(".burger").toggleClass("is-open");
      // $(".gNav").slideToggle(400);
			if ($(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        $(".headerNav__cover").fadeIn(500);
        $(".headerInner").fadeIn(500);
			} else {
        $(".headerNav__cover").fadeOut(500);
        $(".headerInner").fadeOut(500);
			}
		}
	});
  //カバーをクリック
	$(".headerNav__cover,.gNav a,.btn--headerClose").on('click',function() {
    if (default_width <= break_point) {
      $(".burger").removeClass("is-open");
  		$(".headerInner").fadeOut(500);
      $(".headerNav__cover").fadeOut(500);
    }
	});

	/*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        $(".headerInner").hide();
				$(".burger").removeClass("is-open");
				$(".headerNav__cover").hide();
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
        $(".headerInner").css("display","block");
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	var timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			barFix(); //追従処理
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ完了時）
				/*------------------------*/
			} else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
        /*------------------------*/
        if ($(".worksList__item").length) {
          $('.worksList__item .botItem').tile();
        }
			}
		}, 200);
	});
});

$(window).on('load',function() {
  $("body").addClass("load");
});
/*------------------------*/
//関数
/*------------------------*/
/*Media　スライダー*/
var sliderMedia = function() {
	$(function() {
		//slider設置
    var $slider = $('.slider--media');
    var slideCount = $slider.children().length;
    if (slideCount >= 2) {
      $slider.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        accessibility: false,
        dots: true,
        slidesToShow: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,//ブレイクポイントを指定
            settings: {
              draggable: true,
              arrows: true
            }
          }
        ]
      });
    }
	});
}

/*Works　スライダー*/
var sliderWorks = function() {
	$(function() {
		//slider設置
    var $slider = $('.slider--works');
    var slideCount = $slider.children().length;
    if (slideCount >= 4) {
      $slider.slick({
        autoplay: true,
        accessibility: false,
        dots: false,
        slidesToShow: 3,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,//ブレイクポイントを指定
            settings: {
              slidesToShow: 1,
              dots: true,
              arrows: true,
              draggable: true,
              centerMode: true,
              centerPadding:'20%'
            }
          }
        ]
      });
    }
	});
}

var barFix = function() {
	//該当のセレクタなどを代入
	var mainArea = $(".layout--2col__cont"); //メインコンテンツ
	var sideWrap = $(".sideNav"); //サイドバーの外枠
	var sideArea = $(".sideNav__inner"); //サイドバー
	var wd = $(window); //ウィンドウ自体

	//メインとサイドの高さを比べる 
	var mainH = mainArea.innerHeight();
	var sideH = sideWrap.innerHeight();
	if(sideH < mainH && default_width >= break_point) { //メインの方が高ければ色々処理する
		//サイドバーの外枠をメインと同じ高さにしてrelaltiveに（#sideをポジションで上や下に固定するため）
		sideWrap.css({"height": mainH,"position": "relative"});
		//サイドバーがウィンドウよりいくらはみ出してるか
		var sideOver = wd.height()-sideArea.height();
		//固定を開始する位置 = サイドバーの座標＋はみ出す距離
		var starPoint = sideArea.offset().top + (-sideOver);
		//固定を解除する位置 = メインコンテンツの終点
		var breakPoint = sideArea.offset().top + mainH;
		
		wd.scroll(function() { //スクロール中の処理
			if(wd.height() < sideArea.height()){ //サイドメニューが画面より大きい場合
				if(starPoint < wd.scrollTop() && wd.scrollTop() + wd.height() < breakPoint){ //固定範囲内
					sideArea.css({"position": "fixed", "bottom": "0"}); 
				}else if(wd.scrollTop() + wd.height() >= breakPoint){ //固定解除位置を超えた時
					sideArea.css({"position": "absolute", "bottom": "0"});
				} else { //その他、上に戻った時
					sideArea.css("position", "static");
				}
			}else{ //サイドメニューが画面より小さい場合
				var sideBtm = wd.scrollTop() + sideArea.height(); //サイドメニューの終点
				if(mainArea.offset().top < wd.scrollTop() && sideBtm < breakPoint){ //固定範囲内
					sideArea.css({"position": "fixed", "top": "0"});
				}else if(sideBtm >= breakPoint){ //固定解除位置を超えた時
					//サイドバー固定場所（bottom指定すると不具合が出るのでtopからの固定位置を算出する）
					var fixedSide = mainH - sideH;
					sideArea.css({"position": "absolute", "top": fixedSide});
					} else {
						sideArea.css("position", "static");
					}
				}
		});
	} else if (default_width <= break_point) {
		sideWrap.css({"height": "auto","position": "static"});
	}
}

var snsFix = function() {
  var $fixElement = $('.moreList'); // 追従する要素
  var baseFixPoint = $fixElement.offset().top; // 追従する要素の初期位置
  // 要素が追従する処理
  function fixFunction() {
      var windowScrolltop = $(window).scrollTop();
      // スクロールが初期位置を通過しているとき
      if(windowScrolltop >= baseFixPoint && windowScrolltop <= $(document).height() - $(window).height() - $(".footer").innerHeight()) {
        $fixElement.css({"top": windowScrolltop,"bottom":"initial"});
      } else if (windowScrolltop >= baseFixPoint && windowScrolltop >= $(document).height() - $(window).height() - $(".footer").innerHeight()) {
        $fixElement.css({"top": "initial","bottom":0});
      } else {
        $fixElement.css({"top": 20+"vh","bottom":"initial"});
      }
  }

  $(window).on('load scroll', function() {
    if (resize_width >= break_point) {
      fixFunction();
    }
  });
}